import React from "react";

const Stack = () => {
  return (
    <React.Fragment>
      <h3 className="title-font white-text ">
        Here are a some tools & technologies I’ve been working with so far:
      </h3>
      <ul className="stack-section ">
        <li>Procreate</li>
        <li>Figma</li>
        <li>Canva</li>
        <li>HTML5</li>
        <li>CSS3</li>
        <li>SASS</li>
        <li>JavaScript ES6+</li>
        <li>React.js</li>
        <li>RESTful API</li>
        <li>Git</li>
        <li>PostgreSQL</li>
        <li>MongoDB</li>
        <li>SQL</li>
        <li>AWS EC2</li>
        <li>AWS RDS</li>
        <li>Shopify</li>
        <li>SquareSpace</li>
        <li>Netlify</li>
        <li>Heroku</li>
        <li>Node.js</li>
        <li>Express.js</li>
        <li>Mocha/Chai</li>
        <li>Postman</li>
      </ul>
    </React.Fragment>
  );
};
export default Stack;
