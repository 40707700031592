const ExperienceData = [
  {
    id: 1,
    jobtitle: "Full Stack Developer",
    companyname: "Climate Change Real Estate",
    worktype: "Freelance",
    duration: "2021-2022",
    location: "San Francisco, California",
    responsibility: [
      "Designed and developed a web application for a new real estate agent friend to provide potential homebuyers with comprehensive and transparent reports on residential properties in San Francisco.",
      "Utilized Google Places API and Zillow API to retrieve property information and created a bespoke climate migration database in PostgreSQL RDS by scraping every ZIP code in the country and matching them to their respective county and climate migration model on ProPublica.",
      "Deployed the site to an AWS Ubuntu EC2 server, resulting in an intuitive Google address search, with auto-fill, that populates a comprehensive property report.",
      "Key information provided in the report includes facts about the home, photos, approximate value, sales and tax history, comparable homes, and potential climate perils for a typical 30-year mortgage term.",
      "The application's intent is to rapidly generate transparent sales prospectuses for small brokerages, individual agents, and homebuyers lacking large brokerage resources and support.",
      "The application serves as a valuable resource for real estate agents looking to stand out in markets that disproportionately reward wealthy incumbents.``Designed wireframes and prototypes using Figma, built frontend in React and CSS3, and utilized elements in ExpressJS server and Node.js for backend development.",
    ],
  },
  {
    id: 2,
    jobtitle: "Brand Developer, Creator, Copywriter, Web Designer",
    companyname: "Aelwaen",
    worktype: "Contract",
    duration: "2023-Present",
    location: "San Francisco, California",
    responsibility: [
      "Completed Aelwaen project that aimed to build a cost-effective and quickly deployable e-commerce brand for a pre-owned luxury handbag reseller losing up to 40% of her revenue to resellers.",
      "Utilized SquareSpace and AI-powered tools to generate trademarkable brand names, design logos, retouch product photos, generate product descriptions, generate the backstory about the reseller, and create brand-consistent blog posts for SEO purposes.",
      "Checked the California Secretary of State website and cross-referenced with USPTO's TESS database, Godaddy.com for .com web domains and available social media handles to ensure the entity name was available.",
      "Guided the client through connecting bank accounts and personal information to publish the site, receive payments, and manage inventory.",
      "Completed website features an e-commerce store hosting a wide range of pre-owned luxury handbags with financing available and a blog with brand-consistent posts providing insights into the world of luxury handbag restoration and care.",
      "Leverage knowledge of logistics and supply chain relations to identify additional logistics and insurance products that reduced the cost of fulfillment, returns, and theft/loss.",
      "Completed project in under 36 hours, resulting in a registered company and trademarked brand with a marketing plan that enhances the reseller's brand online presence and profitability.",
    ],
  },
  {
    id: 3,
    jobtitle: "Brand Creator, Website Architect",
    companyname: "Take the Rock",
    worktype: "Contract",
    duration: "2020-Present",
    location: "San Francisco CA",
    responsibility: [
      "Built and deployed website with user accounts using SquareSpace and CSS to create a visually pleasing layout for Take The Rock, a veteran nonprofit.",
      "Designed brand logos and optimized website copy for brand consistency and SEO using AI-powered tools.",
      "Created business accounts for payment and donation processing, transferred all social media properties to the client, and developed bespoke merchandise to enhance online visibility and outreach.",
      "Resulted in increased enrollment and brand ambassadorship for the organization."
    ],
  },
  {
    id: 4,
    jobtitle: "91D Power-Generation Equipment Specialist",
    companyname: "United States Army, California National Guard",
    worktype: "Contract",
    duration: "2020-Present",
    location: "223 Military Intelligence Battalion, San Francisco CA",
    responsibility: [
      "Graduated with honors despite no prior mechanical experience.",
      "Learned schematics, diagnostics, and maintenance on military generators ranging from 5kw to 60kw using only primary documentation.",
      "Taught electrical engineering concepts to soldiers with no mathematical or engineering education, resulting in a 100% graduation rate among my students.",
      "Completed 1000+ hours of hands-on training and 100+ hours of classroom instruction.",
    ],
  },
];

const EducationData = [
  {
    name: "HR Immersive",
    status: "Full Stack Developer Boot Camp, Focus on Javascript",
    year: "2021",
  },
  {
    name: "San Francisco State University",
    status: "BS, Computer Science, Minors in Mathematics and Chemistry",
    year: "2020",
  },
];

const CertificationData = [
  {
    name: "JavaScript Algorithms and Data Structures Masterclass",
    source: "Udemy",
    date: "2022",
    creditUrl: "udemy.com",
  },
  {
    name: "Advanced CSS and SASS: Flexbox, Grid, Animations ",
    source: "Udemy",
    date: "2022",
    creditUrl: "udemy.com",
  },
];

export { ExperienceData, EducationData, CertificationData };
