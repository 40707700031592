import Linkedin from "../../assets/images/socials/linkedin-white.png";
// import Linkedin from "../../assets/images/socials/linkedin-pink.svg";
//  import AiOutlineLinkedin
import Github from "../../assets/images/socials/github-white.png";
// import Codepen from "../../assets/images/socials/codepen-pink.svg";
const NavigationSocials = () => {
  // change socials
  return (
    <section className="social-media">
      <a
        href="https://www.linkedin.com/in/daryakutovaya/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={Linkedin} alt="linkedin" />
      </a>
      <a
        href="https://github.com/daryaKuto"
        target="_blank"
        rel="noreferrer"
      >
        <img src={Github} alt="github" />
      </a>
      {/* <a
        href="https://codepen.io/catherineisonline"
        target="_blank"
        rel="noreferrer"
      >
        <img src={Codepen} alt="codepen" />
      </a> */}
    </section>
  );
};

export default NavigationSocials;
