import SendEmail from "../../components/SendEmail";
import { Footer } from "../../components";

const Contact = () => {
  document.title = "Contact | Darya Kutovaya";
  return (
    <article className="contact-main">
      <h2 className="title-font pink-text h2-tag">Contact Me</h2>
      <p className="white-text p-tag">
        As a full stack and frontend developer with a passion for small
        business, I am excited to offer my skills and expertise to help bring
        your brand and business to the forefront of the market. I provide a full
        spectrum of business and brand development services to help small
        businesses thrive. <p></p> Whether you're seeking to build a new website, create
        a brand identity, or develop a comprehensive marketing strategy, I am
        dedicated to working with you every step of the way. I am committed to
        providing personalized and tailored solutions that meet your unique
        needs and exceed your expectations.
      <p></p>
      If you're looking for a reliable and trustworthy partner who can help
        take your small business to the next level, I would love to work with
        you. Please feel free to contact me at any time to discuss your specific
        needs and requirements.</p>
      <SendEmail />
      <Footer />
    </article>
  );
};

export default Contact;
