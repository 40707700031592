import TakeTheRock from "../assets/images/portfolio/taketherock.png";
import ClimateChangeTwo from "../assets/images/portfolio/climate-change2.png";
import Aelwaen from "../assets/images/portfolio/aelwaen.png";
import SweetsFactory from "../assets/images/portfolio/sweets-factory.jpeg";
import FunHorse from "../assets/images/portfolio/fun-horse-boutique.png";
import CourageousCottonwood from "../assets/images/portfolio/courageousCottonwood.png";
import MiamiMedical from "../assets/images/portfolio/MiamiMedical.png";
import Warbird from "../assets/images/portfolio/Warbird.png";

const ProjectsPreviewData = [
  {
    id: "climate-change-analysis",
    img: ClimateChangeTwo,
    name: "Climate Change Analysis",
    stack: [
      "React.js",
      "CSS3",
      "Zillow API",
      "Google Maps API",
      "Express.js",
      "Node.js",
      "Amazon Web Services",
      "Firebase"
    ],
    src: "https://daryakutovaya.org",
    source: "https://github.com/daryaKuto/climateFb",
    description: `I developed this web app for a real estate agent friend to explain the value of buying expensive real estate in San Francisco compared to surrounding areas. The app uses Google Places and Zillow APIs to generate comprehensive property reports on residential properties, and a bespoke climate migration database was integrated in PostgreSQL RDS. The app's frontend is in React and CSS3, and the backend consists of elements in ExpressJS, Node.js and Firebase database, Zillow API , Google API and deployed on Firebase Hosting. Users can search for properties via Google address and receive a report with information on home facts, photos, value, sales and tax history, comparable homes, and potential climate perils. The app is a valuable resource for real estate agents looking to stand out in competitive markets and generate transparent sales reports for small brokerages, individual agents, and homebuyers.`,
  },
  {
    id: "aelwaen-ecommerce",
    img: Aelwaen,
    name: "Aelwaen",
    stack: [
      "Squarespace",
      "CSS3",
      "Canva",
      "JavaScript",
      "HTML5",
      "ChatGPT",
      "MidJourney",
      "Unsplash",
    ],
    src: "https://aelwaen-demo.squarespace.com",
    source: "https://www.aelwaen.com",
    description: `Aelwaen was a project that aimed to build a cost-effective and quickly deployable e-commerce brand for a pre-owned luxury handbag reseller who was losing up to 40% of her revenue to resellers. Using SquareSpace, AI-powered tools, and a folder of product images, I designed a website featuring an e-commerce store, blog, trademarked brand, and marketing plan. The reseller now stands out in the highly competitive market, with a registered company and social media properties connected to the site. Additionally, I leveraged my knowledge of logistics to identify solutions that reduced the cost of fulfillment, returns, and theft/loss. The result was a completed project in under 36 hours, providing a cost-effective and timely solution that enhances the reseller's brand online presence and profitability.`,
  },
  {
    id: "taketherock-nonprofit",
    img: TakeTheRock,
    name: "Take The Rock",
    stack: [
      "Squarespace",
      "CSS3",
      "Canva",
      "JavaScript",
      "HTML5",
      "ChatGPT",
      "MidJourney",
      "Unsplash",
    ],
    src: "https://take-the-rock-demo.squarespace.com/",
    source: "https://www.taketherock.com/",
    description: `Take the Rock is a vital component of the veteran nonprofit community, helping thousands of novice swimmers accomplish the famed Alcatraz prison break swim of Frank Morris and the Anglin. As part of my role, I built and deployed a trademarkable veteran non-profit brand, designed the website with SquareSpace, created business accounts, and identified additional logistics and insurance products to reduce costs. The successful completion of the project resulted in Take the Rock standing out amongst other charities, offering a unique service of training civilians to swim in open water from Alcatraz to San Francisco, aided by veterans.`,
  },
];

const ProjectsData = [
  {
    id: "climate-change-analysis",
    img: ClimateChangeTwo,
    name: "Climate Change Analysis",
    stack: [
      "React.js",
      "CSS3",
      "Zillow API",
      "Google Maps API",
      "Express.js",
      "Node.js",
      "Amazon Web Services",
      "Firebase"
    ],
    src: "https://daryakutovaya.org",
    source: "https://github.com/daryaKuto/climateFb",
    description: `I developed this web app for a real estate agent friend to explain the value of buying expensive real estate in San Francisco compared to surrounding areas. The app uses Google Places and Zillow APIs to generate comprehensive property reports on residential properties, and a bespoke climate migration database was integrated in PostgreSQL RDS. The app's frontend is in React and CSS3, and the backend consists of elements in ExpressJS, Node.js and Firebase database, Zillow API , Google API and deployed on Firebase Hosting. Users can search for properties via Google address and receive a report with information on home facts, photos, value, sales and tax history, comparable homes, and potential climate perils. The app is a valuable resource for real estate agents looking to stand out in competitive markets and generate transparent sales reports for small brokerages, individual agents, and homebuyers.`,
  },
  {
    id: "aelwaen-ecommerce",
    img: Aelwaen,
    name: "Aelwaen",
    stack: [
      "Squaspace",
      "CSS3",
      "Canva",
      "JavaScript",
      "HTML5",
      "ChatGPT",
      "MidJourney",
      "Unsplash",
    ],
    src: "https://aelwaen-demo.squarespace.com",
    source: "https://www.aelwaen.com",
    description: `Aelwaen was a project that aimed to build a cost-effective and quickly deployable e-commerce brand for a pre-owned luxury handbag reseller who was losing up to 40% of her revenue to resellers. Using SquareSpace, AI-powered tools, and a folder of product images, I designed a website featuring an e-commerce store, blog, trademarked brand, and marketing plan. The reseller now stands out in the highly competitive market, with a registered company and social media properties connected to the site. Additionally, I leveraged my knowledge of logistics to identify solutions that reduced the cost of fulfillment, returns, and theft/loss. The result was a completed project in under 36 hours, providing a cost-effective and timely solution that enhances the reseller's brand online presence and profitability. `,
  },
  {
    id: "taketherock-nonprofit",
    img: TakeTheRock,
    name: "Take The Rock",
    stack: [
      "Squarespace",
      "CSS3",
      "Canva",
      "JavaScript",
      "HTML5",
      "ChatGPT",
      "MidJourney",
      "Unsplash",
    ],
    src: "https://take-the-rock-demo.squarespace.com",
    source: "https://www.taketherock.com",
    description: `Take the Rock is a vital component of the veteran nonprofit community, helping thousands of novice swimmers accomplish the famed Alcatraz prison break swim of Frank Morris and the Anglin. As part of my role, I built and deployed a trademarkable veteran non-profit brand, designed the website with SquareSpace, created business accounts, and identified additional logistics and insurance products to reduce costs. The successful completion of the project resulted in Take the Rock standing out amongst other charities, offering a unique service of training civilians to swim in open water from Alcatraz to San Francisco, aided by veterans.`,
  },
  
  {
    id: "fun-horse-boutique",
    img: FunHorse,
    name: "Fun Horse Toy's Boutique",
    stack: [
      "React.js",
      "CSS3",
      "MongoDB",
      "Express.js",
      "Mongoose",
      "Node.js",
      "Amazon Web Services",
    ],
    src: "https://github.com/139BillyOcean/FunHorseToyBoutique",
    source: "https://github.com/139BillyOcean/FunHorseToyBoutique",
    description: `During my time as a UX/UI Designer and Software Engineer at Fun Horse Toy Boutique in 2022, I was deeply involved in developing a full-stack e-commerce app for buying and selling toys. The app included features like a photo gallery, carousel, user reviews, shopping cart, checkout, and user account creation. I took charge of overhauling the UX and UI of an existing CSS website, and created wireframes and prototypes using Figma and Excalidraw. Additionally, I skillfully integrated cohesive color schemes and font themes throughout the project using CSS modules.`  },
  {
    id: "courageous-cottonwood",
    img: CourageousCottonwood,
    name: "Courageous Cottonwood",
    stack: [
      "React.js",
      "CSS3",
      "Heroku API",
      "Express.js",
      "Node.js",
      "Amazon Web Services",
    ],
    src: "https://github.com/courageous-cottonwood/front-end-capstone#hackreactor--front-end-capstone",
    source: "https://github.com/courageous-cottonwood/front-end-capstone#hackreactor--front-end-capstone",
    description: `As a Full Stack Engineer at Courageous Cottonwood in 2022, I contributed significantly to the development of a full-stack e-commerce app showcasing products, ratings, a photo gallery, carousel, and product reviews. I architected a React front-end photo carousel and pop-up modal, supported by a Heroku API. Additionally, I designed the branding, logo, and themes for the application and deployed it on AWS EC2 for on-demand access. My efforts in utilizing dynamic and static compression, as well as resizing and reformatting source images, led to a remarkable 50% improvement in page load time.` },
    {
      id: "sweets-factory",
      img: SweetsFactory,
      name: "Sweets Factory",
      stack: ["JavaScript", "Procreate"],
      src: "https://opensea.io/SweetsFactory",
      source: "https://github.com/daryaKuto/sweetsFactory",
      description:
        "Within a technical exercise timeframe of 48 hours, I created the Sweets Factory NFT collection on opensea.io using the Procreate app. Each element of the image layers was hand-drawn from scratch to ensure authenticity and uniqueness. To add an extra layer of distinction and generate an infinite number of images for use on the NFT market, I utilized JavaScript to randomize the created layers. The collection includes a diverse range of whimsical and vivid images featuring an array of unicorns, sweets and confections.",
    },
    {
      id: "miami-medical",
      img: MiamiMedical,
      name: "Miami Medical",
      stack: [
        "Squarespace",
        "CSS3",
        "Canva",
        "JavaScript",
        "HTML5",
        "Unsplash",
        "Adobe XD",
      ],
      src: "https://Squarespace.com",
      source: "https://Squarespace.com",
      description:
        "As the sole website designer for this medical services provider, I was responsible for inventorying the array of medical services provided by multiple practices across the greater Miami-Dade area. I researched and listed every procedure that each of the medical practices performed, took photos of and provided biographies for each staff member for the website, created web-based intake forms, and integrated the website into the practice workflow. I also inventoried and listed for sale used medical equipment from each of the medical practices to self-fund my costs. This resulted in a complete overhaul of the medical practices' web presence which was funded entirely by the sale of equipment that would otherwise have been discarded. " },
    {
      id: "aero-repair",
      img: Warbird,
      name: "Warbird Repair Services",
      stack: [
        "Squarespace",
        "CSS3",
        "Canva",
        "JavaScript",
        "HTML5",
        "Unsplash",
        "Adobe XD",
      ],
      src:"https://Squarespace.com",
      source: "https://Squarespace.com",
      description:
        "I worked with a small business owner that purchased auction lots of ww2 aircraft, cars, trucks, heavy equipment, and parts. The business was having trouble selling through the inventory it had purchased, so it had an airplane hangar full of items it had retained for decades. I was given varying inventory photos on a sporadic basis and would set up sales and promotions via multiple e-commerce channels to increase sellthrough. I also located local sales organizations and auction houses that specialized in each type of inventory to increase monthly sales volume. As a result, the business was able to purge inventory, at sufficient profitability, to purchase new equipment used to expand its bespoke line of heavy vehicle exhaust parts while concurrently condensing its operations from two locations to one. "  },
  
];

// Used Procreate to design the NTF images from ground up.
// Used JavaScript to randomize created layers and generate unlimited images.
// NFT collection: https://opensea.io/SweetsFactory

export { ProjectsData, ProjectsPreviewData };
