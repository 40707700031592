const PortfolioDescription = () => {
  return (
    <section className="portfolio-text">
      <h2 id ="portfolio" className="title-font pink-text h2-tag">My Portfolio</h2>
      <p className="white-text p-tag">
       I have worked solo and in team with developers to create a variety of projects. I'm passionate about design and automation. 
       I love providing full spectrum of services from design to development to make my clients lives easy.<span>
          {/* <a
            href="https://github.com/catherineisonline?tab=repositories"
            className="pink-text"
            target="_blank"
            rel="noreferrer"
          >
            Github
          </a> */}
        </span>
      </p>
    </section>
  );
};

export default PortfolioDescription;