import { ExperienceData } from "../../data/ExperienceData";

const WorkExperience = () => {
  return (
    <section className="work-experience">
      <h2 className="title-font  pink-text h2-tag">Work Experience</h2>
      {ExperienceData.map((info, index) => (
        <section key={index}>
          <section>
            <h3 className="h3-tag">{info.jobtitle}</h3>
          </section>
          <section>
            <h4>
              {info.companyname} - {info.worktype}
            </h4>
          </section>
          <section>
            <h4>{info.duration}</h4>
          </section>
          <section>
            <h4>{info.location}</h4>
          </section>
          <section>
            <ul className="responsibilities ul-tag">
              {info.responsibility.map((data, index) => (
                <li key={index}>{data}</li>
              ))}
            </ul>
          </section>
        </section>
      ))}
    </section>
  );
};
export default WorkExperience;
