import ProjectsPreview from "./ProjectsPreview";
import { Link } from "react-router-dom";

const PortfolioPreview = () => {
  function ResetLocation() {
    window.scrollTo(0, 0);
  }
  return (
    <article className="portfolio">
      <section className="portfolio-text">
        <h2 className="title-font pink-text h2-tag">My Portfolio</h2>
        <p className="white-text p-tag">
          My expertise in JavaScript (ES5 and ES6), React, HTML5, CSS3, Node.js,
          Express, MongoDB, MySQL, RESTful API Development, and JavaScript
          Testing/Deployment has allowed me to successfully build and deploy
          multiple applications. These include my Climate Change and Property
          Analysis single-page website deployed on AWS EC2 and RDS using
          third-party APIs, my NFT collection of images generated by code, a
          full spectrum e-commerce website and brand creation from scratch for
          buying luxury handbags (Aelwaen). In all of these projects, I have
          shown my ability to architect a valuable product, storyboard and
          optimize user experience, and deploy multi-level structured website in
          record time.
        </p>
      </section>
      <ProjectsPreview />
      <section>
        <Link
          onClick={ResetLocation}
          className="more-projects-btn pink-text"
          to="/portfolio"
        >
          More projects
        </Link>
      </section>
    </article>
  );
};

export default PortfolioPreview;
