import DownloadIcon from "../../assets/images/download-icon.png";
import Resume from "../../assets/documents/DaryaKutovayaST_Resume2023.pdf";
const DownloadResume = () => {
  return (
    <section>
      <a className="download-btn" href={Resume} download="DaryaKutovayaST_Resume2023">
        <img
          src={DownloadIcon}
          alt="Download icon"
          className="download-img"
        ></img>
        Download Resume
      </a>
    </section>
  );
};

export default DownloadResume;

// https://www.taketherock.com/
// http://take-the-rock.squarespace.com/