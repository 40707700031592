const AboutMe = () => {
  return (
    <section>
      <h2 className="title-font pink-text h2-tag">About Me</h2>
      <p className="white-text p-tag">
        I am a full-stack web developer with experience in setting up
        full-spectrum e-commerce for small businesses, including branding,
        social media, content and ad creation, and management. I combine my
        management expertise with technical skills and fully commit to
        consistency, meeting deadlines, and utilizing all available resources to
        achieve results. I enjoy creating beautiful, accessible, and responsive
        websites using HTML, CSS, React, Sass, and JavaScript, as
        well as deploying with AWS and integrating APIs and databases.
      </p>
    </section>
  );
};
export default AboutMe;
